import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Hooks & Querys
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line
import { useNav } from '../lib/context';

import { PageMeta } from '../components/pageMeta';
import BlockZone from '~blockZone';
import Layout from '../components/layout';

const IndexPage = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data && data.page;
  const { title, meta, pageBlocks, reverseHeader, theme } = page || {};
  const { setReverseHeader } = useNav();
  useEffect(() => {
    setReverseHeader(reverseHeader);
  }, [reverseHeader]);

  return (
    <Layout theme={theme}>
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      meta {
        ...MetaCardFields
      }
      reverseHeader
      pageBlocks {
        ...BlockZone
      }
      theme {
        bg
        footerStyle
      }
    }
  }
`;
